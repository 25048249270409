/* Personalizar los colores de la empresa */

/* $primary: green; */
@import url('https://fonts.googleapis.com/css?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

$danger: #F61737;
$md: 16px;
$sm: 8px;
$xs: 4px;
$lg: 32px;
$primary-color: $danger;
$family-primary: 'Quicksand', sans-serif;
$family-secondary: 'Quicksand', sans-serif;
$black: #1c252c;

@import '~bulma/bulma.sass';
@import '~loaders.css';
@import './checkbox.css';
/* Utilities */

.margin-h-md {
  margin-top: $md;
  margin-bottom: $md;
}

.margin-h-lg {
  margin-top: $lg;
  margin-bottom: $lg;
}

.margin-right-md {
  margin-right: $md;
}

.margin-left-md {
  margin-left: $md;
}

.margin-left-sm {
  margin-left: $sm;
}

.margin-left-xs {
  margin-left: $xs;
}

.margin-top-sm {
  margin-top: $sm;
}

.margin-top-lg {
  margin-top: $lg;
}

.margin-bottom-lg {
  margin-bottom: $lg;
}

.padding-left-md {
  margin-left: $md;
}

.text-is-xl {
  font-size: 150px !important;
}

.logo {
  background-image: url('./images/LogoBlancoColor.png');
  height: 52px;
  width: 150px;
  background-size: 100px 45px;
  background-repeat: no-repeat;
  background-position: center;
}

.logo-secundario {
  background-image: url('./images/logo-secundario.png');
  height: 52px;
  width: 150px;
  background-size: 100px 45px;
  background-repeat: no-repeat;
  background-position: center;
}

.action-bar {
  font-size: 10px;
}

.mensaje-notificacion {
  width: 400px;
  display: block;
  z-index: 999;
  position: fixed;
  right: 0;
  opacity: 0.9;
  text-align: justify;
  /* color: black !important; */
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

output.input {
  background-color: #f9f9f9;
}

.file-label {
  width: 100%;
}

.file-name {
  width: 100%;
  max-width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.finder-items {
  &:hover {
    background-color: #dedededb;
  }
}

.conciliate-order {
  &:hover {
    background-color: #f8f9fe;
  }
  &.selected {
    background-color: #9de7e0;
    /* color: white !important; */
  }
  &.disabled {
    background-color: #efefef;
    border: 1px solid gray;
    cursor: not-allowed;
  }
  &.selectable {
    cursor: pointer;
  }
}

.loader-table:after {
  background: url('./images/spinner.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100px 100px;
  background-color: #f2f2f2 !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  pointer-events: none;
  z-index: 1000;
}

.credit-note-form {
  z-index: 900;
  position: absolute;
  width: 97%;
  min-height: 99%;
  background-color: white;
}

.icons {
  height: 96px;
}

.app-padding{
  padding-left: 32px;
  padding-right: 32px;
}

@media screen and (max-width: 768px) {
  .app-padding{
    padding-left: 0px;
    padding-right: 0px;
  }
}
